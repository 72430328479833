import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import "../design/footer.css"

const Footer = () => {
    return(
        <div className="bodyF">
            <div className="above_footer">
                <p>Excelente Technologies</p>
                <div className="div_af">
                    <p>"nobody teaches public cloud hands-on"</p>
                </div>
            </div>
            <div className="footer">
                <div className="f_div1">
                    <div className="insta">
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" color="white"/>
                        </a>
                    </div>
                    <div className="linkedin">
                        <a href="https://www.linkedin.com/company/excellente-technologics/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" color="white" />
                        </a>
                    </div>
                    <div className="fb">
                        <a href="https://www.facebook.com/ExcellenteTechnologies" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2x" color="white" />
                        </a>
                    </div>
                    <div className="yt">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
                        </a>
                    </div>
                </div>
                <div className="f_div2">
                    <a>Home</a>
                    <a>Pricing & offering</a>
                    <a>About</a>
                    <a>Terms</a>
                    <a>Privacy Policy</a>
                </div>
                <div className="f_div3">
                    <p>Copyright&copy;2024 Excelente technologies</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;