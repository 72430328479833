import React, { useEffect, useState } from 'react';
import '../design/Tutorial.css'
import LockIcon from '@mui/icons-material/Lock'
import Cookies from 'js-cookie'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { api } from '../CONSTANTS';

const Tutorial=()=> {
    const [activeButton, setActiveButton] = useState(0)
    const [selectedOpt,setSelectedopt]=useState("")
    const buttons=["Using Console", "Using CLI", "Using API"]
    const [selectedItems, setselectedItems] = useState([])
    const [sections,setSections] = useState([])

    useEffect(()=>{        
        if(Cookies.get("isLoggedIn") && Cookies.get("isLoggedIn")==="true")
            Get_AllDetails(Cookies.get("id"))
        else
            Get_AllDetails(-1)
    },[])

    useEffect(()=>{
        if (selectedOpt==="")return
        const expandedSection = sections.find(
            section => section.expanded === true
        )?.items.find(item => item.title === selectedOpt)
        if (expandedSection)
            setselectedItems(expandedSection.steps[activeButton])
    },[selectedOpt,activeButton])

    const Get_AllDetails = async (uid)=>{
        try {
            const response = await fetch(`${api}get_tutorial?user_id=${encodeURIComponent(uid)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', },
            });
            const data = await response.json();
            if (response.status === 201) {
                setSections(data.message)
            } else {
                alert(data.message);
                setSections([])
            }
        } catch (error) {
            console.error(error);
            setSections([])
            alert('An error occurred. Please try again.');
        }
    }
    const toggleDropdown = (index) => {
        setSections((prevSections) =>
            prevSections.map((section, i) => ({
                ...section,
                expanded: i === index ? !section.expanded : false,
            }))
        );
    }

    return (
        <div className="vm-page">
            <nav className="vm-navbar">
                <h1>Tutorials</h1>
            </nav>
            
            <div className="vm-container">
                
                <aside className="vm-sidebar">
                    {sections.length>0 && sections.map((section, index) => (
                        <div key={index}>
                            <h2 onClick={() => {!section.locked && toggleDropdown(index)}}>
                                {section.title}
                                {section.locked && <LockIcon style={{color:'blue'}}/>}
                            </h2>
                            <ul className={`azure-services ${sections[index].expanded ? 'expanded' : ''}`}>
                                {section.items.map((item, idx) => (
                                <li key={idx} 
                                onClick={()=>{!item.locked && setSelectedopt(item.title)}}
                                >
                                    {item.title}
                                    {item.locked && <LockIcon style={{color:'red'}}/>}
                                </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </aside>

                {selectedOpt !=="" ? <main className="vm-main-content">
                    <h2>{selectedOpt}</h2>
                    <div className="vm-learning-resources more-resource">
                        {buttons.map((label, index) => (
                            <button key={index} onClick={() => setActiveButton(index)}
                            className={activeButton === index ? "active" : ""}
                            >
                            {label}
                            </button>
                        ))}
                    </div>
                    <div className="vm-learning-resources">
                        {selectedItems.length > 0 && selectedItems.map((snippet, index) => (
                        <CodeSnippets 
                            key={index} 
                            heading={`${index+1}. ${snippet.heading}`} 
                            description={snippet.description} 
                            code={snippet.code?snippet.code:null} 
                            img_name={snippet.img?snippet.img:null} 
                        />
                        ))}
                    </div>
                </main>:<main className="vm-main-content" 
                style={{color:'blue',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'30px'}}>
                    Select your Tutorial
                </main>}
                
            </div>
        </div>
    );
}

const CodeSnippets = ({heading, code, img_name, description}) => {
    const [copy,setCopy]=useState(false)
    const [imageSrc, setImageSrc] = useState(null)

    useEffect(() => {
        if(img_name===null){
            setImageSrc(null)
            return
        }
        import(`../images/${img_name}`)
            .then((image) => setImageSrc(image.default))
            .catch(() => {
                console.error("Image loading failed for", img_name);
                setImageSrc(null);
            });
    }, [img_name,code])

    const handleCopy = () => {
        setCopy(true);
        setTimeout(() => {
            setCopy(false)
        }, 2000)
    }
    return(
        <div className='each_snippet' style={{color:'black'}}>
            <h3>{heading}</h3>
            {imageSrc &&
                <img src={imageSrc} alt='Image' width={'100%'} style={{ border: '2px solid black' }}/>
            }
            {code !==null && <div style={{ position: "relative", background: "#282C34", padding: "1rem", borderRadius: "10px",margin:'2px', overflow:'auto' }}>
                <pre style={{ color: "#FFF", margin: 0 }}> <code>{`\n${code}`}</code> </pre>
                <CopyToClipboard text={code} onCopy={handleCopy}>
                    <FileCopyIcon style={{
                        position: "absolute",top: "10px",right: "10px",
                        background:copy?'blue':'#f3f3f3',
                        color: copy?'white':"#000",
                        border: "none",padding: "5px 10px",zIndex: 10,
                        cursor: "pointer",borderRadius: "3px"
                    }}/>
                </CopyToClipboard>
            </div>}
            <p>{description}</p>
        </div>
    )
}

export default Tutorial
