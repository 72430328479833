import React, { useState, useEffect } from "react";
import "../design/home.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faBookOpenReader, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";


const Home = () => {
  const [openSection, setOpenSection] = useState(null);
  useEffect(() => {
    // window.location.reload(); 
  }, [])
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  return (
    <div className="bodyH">
      <div className="navbar1">
        <div className="logo">
          <img src="/assets/etcnew-removebg-preview 1.png" alt="" />
        </div>
        <div>
          <Navbar />
        </div>
      </div>
      <div className="div_carousel">
        <div
          className="carousel"
          style={{
            background: "url('/assets/Group 1 (1).png') no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="para">
            <div className="para1">
              <p style={{ color: "orange" }}>Empower Your Skills</p>
            </div>
            <p>With</p>
            <p style={{ color: "orange" }}><p>AZURE</p> | AWS | <p>GCP</p></p>
            <div className="side">
               <img className="i1" src="/assets/azure.png" alt="" />
               <img className="i2" src="/assets/awslogo.png" alt="" />
               <img className="i3" src="/assets/gcp.jpg" alt="" />
            </div>
            
            <div className="buy">
              <Link to="/pricing">
              <button>Try Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="overview">
        <p className="overviewmaintext">Overview</p>
        <p className="overviewsubtext">Enjoy the cheapest services of cloud with AWS/AZURE/GCP to learn</p>
        <div className="overview1">
          <div className="content">
            <div className="accordion">
              <div className="section">
                <div className="title" onClick={() => toggleSection("blob")}>
                  <h2>Azure Blob Storage</h2>
                  <span>{openSection === "blob" ? "▲" : "▼"}</span>
                </div>
                {openSection === "blob" && (
                  <div className="content">
                    <p>
                    Create Bucket and access it from Azure by using our tutorial paying a small fee <a href="#">click next for more</a>
                    </p>
                  </div>
                )}
              </div>

              <div className="section">
                <div className="title" onClick={() => toggleSection("vm")}>
                  <h2>Azure Virtual Machine</h2>
                  <span>{openSection === "vm" ? "▲" : "▼"}</span>
                </div>
                {openSection === "vm" && (
                  <div className="content">
                    <p>
                    Create VM from Azure by using our tutorial paying a small fee <a href="#">click next for more</a>
                    </p>
                  </div>
                )}
              </div>

              <div className="section">
                <div
                  className="title"
                  onClick={() => toggleSection("function")}
                >
                  <h2>Azure Function</h2>
                  <span>{openSection === "function" ? "▲" : "▼"}</span>
                </div>
                {openSection === "function" && (
                  <div className="content">
                    <p>
                    Create Azure Function and run it from Azure by using our tutorial paying a small fee <a href="#">click next for more</a>
                    </p>
                  </div>
                )}
              </div>
              {/* <div className="section">
                <div
                  className="title"
                  onClick={() => toggleSection("tutorial")}
                >
                  <h2>Tutorial</h2>
                  <span>{openSection === "tutorial" ? "▲" : "▼"}</span>
                </div>
                {openSection === "tutorial" && (
                  <div className="content">
                    <p>
                      Protect your data with enterprise-grade security features.<br/>
                      <Link to={"/tutorial"}>
                      <button>Tutorial<FontAwesomeIcon icon={faSquareArrowUpRight} size="xl" style={{ marginLeft: "5px" }} /></button>
                      </Link>
                    </p>
                  </div>
                )}
              </div> */}
              {/* <div className="section">
                <div
                  className="title"
                  onClick={() => toggleSection("certificate")}
                >
                  <h2>Earn your certificate </h2>
                  <span>{openSection === "certificate" ? "▲" : "▼"}</span>
                </div>
                {openSection === "certificate" && (
                  <div className="content">
                    <p>
                      Protect your data with enterprise-grade security features.
                    </p>
                  </div>
                )}
              </div> */}
            </div>
          </div>
          <div className="overview_image">
            <img src="/assets/istockphoto-1416048929-612x612.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="get_started">
        <p className="get_startedp">Get Started</p>
        <div className="get_started1">
          <div className="create">
            <FontAwesomeIcon icon={faIdCard} style={{ color: 'white', fontSize: '64px'  }} />
            <h3>Sign up for Cheap Cloud</h3>
            <p className="sub">Please register and find out all our services in detail</p>
          </div>
          <div className="create">
            <FontAwesomeIcon icon={faBookOpenReader} style={{ color: 'white', fontSize: '64px' }} />
            <h3>Tutorial</h3>
            <p className="sub">Practice commands with our integrated, hands-on course tailored for all skill levels.</p>
          </div>
          <div className="create">
            <FontAwesomeIcon icon={faScrewdriverWrench} style={{ color: 'white', fontSize: '64px' }} />
            <h3>Start building</h3>
            <p className="sub">Experience to learn public cloud in CONSOLE, API and CLI</p>
          </div>
        </div>
      </div>
      <div className="offering">
                <div className="why_cloud">
                    <span className="features-page-text25">
                    <span className="features-page-text26">Why</span>
                    <span className="features-page-text27">
                        {' '}
                        Choose
                        <span
                        dangerouslySetInnerHTML={{
                            __html: ' ',
                        }}
                        />
                    </span>
                    <span className="features-page-text28">Excellente</span>
                    <span className="features-page-text29">
                        {' '}
                        Cheap
                        <span
                        dangerouslySetInnerHTML={{
                            __html: ' ',
                        }}
                        />
                    </span>
                    <span className="features-page-text30">Cloud</span>
                    <span> ?</span>
                    </span>
                </div>
                <div className="features-page-rectangle49">
                    <div className="features-page-rectangle531">
                        <img
                        src="/assets/image 2.svg"
                        alt="hologramprojectorscreenwithcloudsystemtechnology1I819"
                        className="features-page-hologramprojectorscreenwithcloudsystemtechnology1"
                        />
                        <span className="features-page-text34">
                        <span>Affordable Cloud Access</span>
                        </span>
                    </div>
                    <div className="features-page-rectangle532">
                        <img
                        src="/assets/image 1.svg"
                        alt="websitehostingconceptwithwomanworkingcomputerI819"
                        className="features-page-websitehostingconceptwithwomanworkingcomputer"
                        />
                        <span className="features-page-text36">
                        <span>Hands on Lab Session</span>
                        </span>
                    </div>
                    <div className="features-page-rectangle533">
                        <span className="features-page-text38">
                        <span>Step-by-Step Tutorial</span>
                        </span>
                        <img
                        src="/assets/aws.jpg"
                        alt="websitehostingconceptwithfuturisticcompositionI819"
                        className="features-page-websitehostingconceptwithfuturisticcomposition"
                        />
                    </div>
                </div>
            </div>
            <div className="offering1"></div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
