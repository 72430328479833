import '../design/login-register.css';
import React, { useState, useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import {api} from '../CONSTANTS'

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' })

    useEffect(() => {
        // Check if the user is already logged in
        if (Cookies.get('isLoggedIn')) {
            alert('You are already Signed in.')
            navigate('/home');
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target; 
        setFormData({
          ...formData, 
          [name]: value 
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(formData)
    
        try {
            const response = await fetch(`${api}login`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            const data = await response.json()        
            if (response.status === 200) {
                // localStorage.setItem('isLoggedIn', 'true');
                console.log(data)
                if (data.id) {
                    Cookies.set('id', data.id, { expires: 3/24 })
                    Cookies.set('name', data.name, { expires: 3/24 })
                    Cookies.set('isLoggedIn', true, { expires: 3/24 })
                }
                // window.alert('Login successful!');
                navigate('/home'); 
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('An error occurred during login. Please try again.');
        }
    }

    return (
        <div className="container">
            <div className="left-side">
                <h1>Welcome Back !</h1>
                <p>Learn Hands On Cloud using Cloud Console, CLI and API</p>
                {/* <button className="sign-in-btn">SIGN IN</button> */}
            </div>
            <div className="right-side">
                <h2 style={{color:'darkblue'}}>Sign In</h2>
                <form className="register-form" onSubmit={handleSubmit}>
                    <input name="email" type="email" placeholder="Email..." required
                    value={formData.email} onChange={handleChange}/>

                    <input name="password" type="password" placeholder="Password..." required
                    value={formData.password} onChange={handleChange}/>

                    <p style={{color:'darkblue'}}>
                        Don't have an account? 
                        <Link to="/register" className="login-link"> Register</Link>
                        <br/><br/>
                        Forgot Password? 
                        <Link to="/updatepwd" className="login-link"> Update</Link>
                    </p>
                    
                    <button className="register-btn">Login</button>
                </form>
            </div>
        </div>
    )
}

export default Login
