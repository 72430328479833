import React, { useState, useEffect } from 'react';
import "../design/pricing.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Cookies from 'js-cookie'; 
import { Link, useNavigate } from 'react-router-dom';

const Pricing = () => {
    
    const navigate = useNavigate();
    const [selectedService, setSelectedService] = useState('AZURE Virtual Machine'); 
    useEffect(() => {
        // Check if the user is already logged in
        if (!Cookies.get('isLoggedIn')) {
            navigate('/login');
        }
    }, [navigate]);

    const setServiceID = (id) => {
        Cookies.set('serviceID', id);
        navigate('/payment'); // Navigate to the payment page after setting the cookie
    };
    useEffect(() => {

    },[selectedService])

    return (
        <div className="mainbody">
            <div className="navbar1">
                <div className="logo">
                    <img src="/assets/etcnew-removebg-preview 1.png" alt="" />
                </div>
                <div>
                    <Navbar />
                </div>
            </div>
            {/* --------------------------------------- */}
            <div className="subbody">
                <div className="leftsubbody">
                    <video autoPlay loop muted>
                        <source src="/assets/background.mp4" />
                    </video>
                </div>
                <div className="rightsubbody">
                    <div className="subnav">
                        <li className="subnavli">
                           <div className="virtual" onClick={()=>setSelectedService("AZURE Virtual Machine")}>AZURE Virtual Machine</div>
                           <div className="blob" onClick={()=>setSelectedService("AZURE Blob Storage")}>AZURE Blob Storage</div>
                        </li>
                    </div>
                    {/* --------- */}
                      <div className="header1-s">
                        <p>The Perfect Plan For Your Needs</p>
                      </div>
            { selectedService === "AZURE Virtual Machine" && <div className="container-s">
                <div className="container1-s">
                    <div className="cost-s">
                        <div className="amount-s">
                            <p>&#8377;500</p>
                        </div>
                    </div>
                    <div className="benifit-s">
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Azure Virtual Machine for 5 days</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Learn operate from Console, CLI, API</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Please make the payment and  grab the opportunity</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>You will get an invitation mail within 24 hours</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>Once you have accepted the invitation open <a href="https://portal.azure.com/">portal.azure.com</a></p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Login with your Id and password</p>
                        </div>
                    </div>
                    <div className="button1-s">
                        <button onClick={() => setServiceID(3)}>Get Started</button>
                        <div><br></br><br></br><br></br></div>
                    </div>
                </div>
                <div className="container3-s">
                    <div className="cost-s">
                        <div className="amount-s">
                            <p>&#8377;700</p>
                        </div>
                    </div>
                    <div className="benifit-s">
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Azure Virtual Machine for 7 days</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Learn operate from Console, CLI, API</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Please make the payment and  grab the opportunity</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>You will get an invitation mail within 24 hours</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>Once you have accepted the invitation open <a href="https://portal.azure.com/">portal.azure.com</a></p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Login with your Id and password</p>
                        </div>
                    </div>
                    <div className="button1-s">
                        <button onClick={() => setServiceID(5)}>Get Started</button>
                    </div>
                </div>
            </div>}

  { selectedService === "AZURE Blob Storage" && <div className="container-s">
                <div className="container1-s">
                    <div className="cost-s">
                        <div className="amount-s">
                            <p>&#8377;500</p>
                        </div>
                    </div>
                    <div className="benifit-s">
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Azure Blob Storage for 5 days</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Learn operate from Console, CLI, API</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Please make the payment and grab the opportunity</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>You will get an invitation mail within 24 hours</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>Once you have accepted the invitation open <a href="https://portal.azure.com/">portal.azure.com</a></p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Login with your Id and password</p>
                        </div>
                    </div>
                    <div className="button1-s">
                        <button onClick={() => setServiceID(1)}>Get Started</button>
                        <div><br></br><br></br><br></br></div>
                    </div>
                </div>
                <div className="container3-s">
                    <div className="cost-s">
                        <div className="amount-s">
                            <p>&#8377;700</p>
                        </div>
                    </div>
                    <div className="benifit-s">
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Azure Blob Storage for 7 days</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Learn operate from Console, CLI, API</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Please make the payment and grab the opportunity</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>You will get an invitation mail within 24 hours</p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p style={{fontWeight: 'bold'}}>Once you have accepted the invitation open <a href="https://portal.azure.com/">portal.azure.com</a></p>
                        </div>
                        <div className="b1-s">
                            <img src="/assets/image 12 (1).svg" alt="" />
                            <p>Login with your Id and password</p>
                        </div>
                    </div>
                    <div className="button1-s">
                        <button onClick={() => setServiceID(6)}>Get Started</button>
                    </div>
                </div>
            </div>}
                    {/* ---------- */}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default Pricing;
