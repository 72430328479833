import React, { useEffect, useState } from 'react';
import '../design/Admin.css';
import { api } from '../CONSTANTS';

const Admin = () => {
  const [executedQuery, setExecutedQuery] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api}admin_payment_table`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if (response.status === 200) {
          console.log(data['message']);
          setExecutedQuery(data['message']);
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleSelectPayment = (id) => {
    setSelectedPayments((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((paymentId) => paymentId !== id)
        : [...prevSelected, id]
    );
  };

  const handleVerifySelected = async () => {
    try {
      console.log(selectedPayments)
      const response = await fetch(`${api}admin_payment_table`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payment_ids: selectedPayments }),
      });
      const result = await response.json();
      if (response.status === 200) {
        alert('Payments verified successfully!');
        setExecutedQuery((prev) =>
          prev.filter((payment) => !selectedPayments.includes(payment.id))
        );
        setSelectedPayments([]);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error(error);
      alert('Verification failed. Please try again.');
    }
  };

  const handleAuthorize = async () => {
    try {
        const response = await fetch(`${api}mail_all`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        if (response.status === 200) {
            alert("Success")
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred. Please try again.');
      }
  };

  return (
    <div style={{ padding: '20px', height: '100%', background: 'white', height: '100vh' }}>
      <h1 style={{ color: 'black' }}>Admin Table</h1>
      {executedQuery.length > 0 && (
        <>
          <table className="grid-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Select</th>
                {Object.keys(executedQuery[0]).map((key, index) => (
                  <th key={index} style={{ border: '1px solid #ccc', padding: '8px' }}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {executedQuery.map((item) => (
                <tr key={item.id}>
                  <td style={{ textAlign: 'center' }}>
                    <input
                      type="checkbox"
                      checked={selectedPayments.includes(item.id)}
                      onChange={() => handleSelectPayment(item.id)}
                    />
                  </td>
                  {Object.values(item).map((value, idx) => (
                    <td key={idx} style={{ border: '1px solid #ccc', padding: '8px' }}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {executedQuery.length === 0 && <p style={{ color: 'black' }}>Loading...</p>}
          <button
            onClick={handleVerifySelected}
            style={{ marginTop: '20px', padding: '10px 20px', cursor: 'pointer' }}
            disabled={selectedPayments.length === 0}
          >
            Verify Selected
          </button>
          <button
            onClick={handleAuthorize}
            style={{ marginTop: '20px', padding: '10px 20px', cursor: 'pointer' }}
            // disabled={selectedPayments.length === 0}
          >
            Authorize All
          </button>
    </div>
  );
};

export default Admin;
