import React, { useState, useEffect } from "react";
import '../design/login-register.css'
import { useNavigate } from "react-router-dom";
import { api } from "../CONSTANTS";

const UpdatePassword = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [load, setLoad] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [timer, setTimer] = useState(30);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        let countdown;
        if (step === 2 && timer > 0) {
            countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
        } else if (timer === 0) {
            setCanResend(true);
            clearInterval(countdown);
        }
        return () => clearInterval(countdown);
    }, [step, timer]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setLoad(true);
        try {
            const response = await fetch(`${api}send_otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });
            const data = await response.json();
            if (response.status === 200) {
                setStep(2);
                setTimer(30);
                setCanResend(false);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('OTP generation failed:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setLoad(false);
        }
    };

    const handleResendOtp = async () => {
        setLoad(true);
        try {
            const response = await fetch(`${api}send_otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });
            const data = await response.json();
            if (response.status === 200) {
                setTimer(30);
                setCanResend(false);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Resend OTP failed:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setLoad(false);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${api}send_otp?email=${encodeURIComponent(email)}&otp=${encodeURIComponent(otp)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', },
            });
            const data = await response.json();
            if (response.status === 200) {
                setStep(3);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred. Please try again.');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("New password and confirm password do not match.");
            return;
        }
        try {
            const response = await fetch(`${api}register?email=${encodeURIComponent(email)}&password=${encodeURIComponent(newPassword)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', },
            });
            const data = await response.json();
            if (response.status === 200) {
                alert("Password updated successfully!");
                navigate("/");
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="container">
            <div className="left-side">
                <h1>Update Password</h1>
                <p>Ensure your password is strong and secure!</p>
                <button className="sign-in-btn" onClick={() => navigate('/login')}>Back to Sign In</button>
            </div>

            <div className="right-side">
                {step === 1 && (
                    <div>
                        <h2>Enter Your Email</h2>
                        <form className="register-form" onSubmit={handleEmailSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit" className="register-btn" disabled={load}>
                                {load ? "Sending OTP..." : "Send OTP"}
                            </button>
                        </form>
                    </div>
                )}

                {step === 2 && (
                    <div>
                        <h2>Enter OTP</h2>
                        <form className="register-form" onSubmit={handleOtpSubmit}>
                            <input
                                type="text"
                                placeholder="Enter the OTP sent to your email"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                            />
                            <button type="submit" className="register-btn" disabled={load}>
                                {load ? "Sending OTP..." : "Verify OTP"}
                            </button>
                        </form>
                        <div>
                            {canResend ? (
                                <p onClick={handleResendOtp}
                                style={{color:'blue',fontWeight:'300',cursor:"pointer"}}>Resend OTP</p>
                            ) : (
                                <p style={{color:'black'}}>Resend OTP in {timer} seconds</p>
                            )}
                        </div>
                    </div>
                )}

                {step === 3 && (
                    <div>
                        <h2>Set New Password</h2>
                        <form className="register-form" onSubmit={handlePasswordSubmit}>
                            <input
                                type="password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Confirm New Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="register-btn">
                                Update Password
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UpdatePassword;
