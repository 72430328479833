import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import UpdatePassword from './pages/UpdatePassword'
import Payment from './pages/Payment'
import Pricing from './pages/Pricing'
import Tutorial from './pages/Tutorial'
// import { Dashboard } from '@mui/icons-material';
import Dash from './pages/Dashboard'
import Newpricing from './pages/Newpricing'
import Admin from './pages/Admin'

function App() {
  return (
    <div className="App">
      <Router><Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/updatepwd" element={<UpdatePassword/>}/>
        <Route path="/dashboard" element={<Dash/>}/>
        <Route path="/payment" element={<Payment/>}/>
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path="/tutorial" element={<Tutorial/>}/>
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/newpricing" element={<Newpricing/>}/>
      </Routes></Router>
    </div>
  )
}

export default App;