import '../design/login-register.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import { api } from '../CONSTANTS';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ 
        name:'', 
        email: '', 
        phone:'',
        password: '',
        conf_password: '' 
    })

    useEffect(() => {
        // Check if the user is already logged in
        if (Cookies.get('isLoggedIn')) {
            alert('You are already Signed in.')
            navigate('/home');
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target; 
        setFormData({
          ...formData, 
          [name]: value 
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData)
        if(formData.password !== formData.conf_password){
            alert("The passwords do not match!")
            return
        }
    
        try {
            const response = await fetch(`${api}register`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
        
            const data = await response.json();
            if (response.status === 200) {
                // localStorage.setItem('isLoggedIn', 'true');
                if (data.id) {
                    Cookies.set('id', data.id, { expires: 3/24 });
                    Cookies.set('isLoggedIn', true, { expires: 3/24 })
                }
                // alert('Login successful!');
                navigate('/home'); 
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('An error occurred during registration. Please try again.');
        }
    }

    return (
        <div className="container">
            <div className="left-side">
                <h1>Welcome !</h1>
                <p>Learn Hands On Cloud using Cloud Console, CLI and API</p>
                {/* <button className="sign-in-btn">REGISTER</button> */}
            </div>
            <div className="right-side">
                <h2 style={{color:'darkblue'}}>Create Account</h2>
                <form className="register-form" onSubmit={handleSubmit}>
                    <input name="name" type="text" placeholder="Name...." required
                    value={formData.name} onChange={handleChange}/>

                    <input name="email" type="email" placeholder="Email..." required
                    value={formData.email} onChange={handleChange}/>
                    
                    <input name="phone" type='number' placeholder="Phone..." required
                    value={formData.phone} onChange={handleChange}/>

                    <input name="password" type="password" placeholder="Password..." required
                    value={formData.password} onChange={handleChange}/>
                    
                    <input name="conf_password" type="password" placeholder="Confirm Password..." required
                    value={formData.conf_password} onChange={handleChange}/>

                    <p style={{color:'darkblue'}}>Already have an account? <Link to='/login' className="login-link">Login</Link></p>
                    <button className="register-btn">Register</button>
                </form>
            </div>
        </div>
    )
}

export default Register
